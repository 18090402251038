var modal = document.getElementById("myModal");
var img = document.getElementById("myImg");
var modalImg = document.getElementById("img01");
var captionText = document.getElementById("caption");
var span = document.getElementsByClassName("close")[0];

img.onclick = function () {
  modal.style.display = "block";
  modalImg.src = this.src;
  modalImg.alt = this.alt;
  captionText.innerHTML = this.alt;
};
span.onclick = function () {
  modal.style.display = "none";
};
// Add an event listener to the document to close the modal when clicking outside it
document.addEventListener("click", function (event) {
  if (event.target == modal) {
    modal.style.display = "none";
  }
});
// scroll up to button js
jQuery(document).ready(function ($) {
  const $scrollToTopButton = $("#scrollToTopButton");

  $(window).scroll(function () {
    if ($(this).scrollTop() > 20) {
      $scrollToTopButton.fadeIn();
    } else {
      $scrollToTopButton.fadeOut();
    }
  });

  $scrollToTopButton.click(function () {
    $("html, body").animate({ scrollTop: 0 }, 300);
    return false;
  });
});

//   aos animation js
AOS.init({
  duration: 800,
  easing: "slide",
});

jQuery(document).ready(function ($) {
  "use strict";

  var isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
      return (
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows()
      );
    },
  };

  var fullHeight = function () {
    $(".js-fullheight").css("height", $(window).height());
    $(window).resize(function () {
      $(".js-fullheight").css("height", $(window).height());
    });
  };
  fullHeight();

  // Scrollax
  $.Scrollax();

  // owl carousel
  $(".carousel-car").owlCarousel({
    //   center: true,
    loop: true,
    autoplay: true,
    margin: 5,
    stagePadding: 0,
    nav: true,
    navText: [
      '<i class="fa-solid fa-2x fa-chevron-left "></i>',
      '<i class="fa-solid fa-2x fa-chevron-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  });



  $(".carousel-testimony").owlCarousel({
    items: 1,
    center: true,
    loop: true,
    margin: 30,
    stagePadding: 0,
    nav: true,
    navText: [
      '<i class="fa-solid fa-2x fa-chevron-left"></i>',
      '<i class="fa-solid fa-2x fa-chevron-right"></i>',
    ],
  });

  // $('nav .dropdown').hover(function () {
  // 	var $this = $(this);
  // 	// 	 timer;
  // 	// clearTimeout(timer);
  // 	$this.addClass('show');
  // 	$this.find('> a').attr('aria-expanded', true);
  // 	// $this.find('.dropdown-menu').addClass('animated-fast fadeInUp show');
  // 	$this.find('.dropdown-menu').addClass('show');
  // }, function () {
  // 	var $this = $(this);
  // 	// timer;
  // 	// timer = setTimeout(function(){
  // 	$this.removeClass('show');
  // 	$this.find('> a').attr('aria-expanded', false);
  // 	// $this.find('.dropdown-menu').removeClass('animated-fast fadeInUp show');
  // 	$this.find('.dropdown-menu').removeClass('show');
  // 	// }, 100);
  // });

  // scroll
  var scrollWindow = function () {
    $(window).scroll(function () {
      var $w = $(this),
        st = $w.scrollTop(),
        navbar = $(".ftco_navbar"),
        sd = $(".js-scroll-wrap");

      if (st > 150) {
        if (!navbar.hasClass("scrolled")) {
          navbar.addClass("scrolled");
        }
      }
      if (st < 150) {
        if (navbar.hasClass("scrolled")) {
          navbar.removeClass("scrolled sleep");
        }
      }
      if (st > 350) {
        if (!navbar.hasClass("awake")) {
          navbar.addClass("awake");
        }

        if (sd.length > 0) {
          sd.addClass("sleep");
        }
      }
      if (st < 350) {
        if (navbar.hasClass("awake")) {
          navbar.removeClass("awake");
          navbar.addClass("sleep");
        }
        if (sd.length > 0) {
          sd.removeClass("sleep");
        }
      }
    });
  };
  scrollWindow();

  var contentWayPoint = function () {
    var i = 0;
    $(".ftco-animate").waypoint(
      function (direction) {
        if (
          direction === "down" &&
          !$(this.element).hasClass("ftco-animated")
        ) {
          i++;

          $(this.element).addClass("item-animate");
          setTimeout(function () {
            $("body .ftco-animate.item-animate").each(function (k) {
              var el = $(this);
              setTimeout(
                function () {
                  var effect = el.data("animate-effect");
                  if (effect === "fadeIn") {
                    el.addClass("fadeIn ftco-animated");
                  } else if (effect === "fadeInLeft") {
                    el.addClass("fadeInLeft ftco-animated");
                  } else if (effect === "fadeInRight") {
                    el.addClass("fadeInRight ftco-animated");
                  } else {
                    el.addClass("fadeInUp ftco-animated");
                  }
                  el.removeClass("item-animate");
                },
                k * 50,
                "easeInOutExpo"
              );
            });
          }, 100);
        }
      },
      { offset: "95%" }
    );
  };
  contentWayPoint();

  // magnific popup
  // $('.image-popup').magnificPopup({
  // 	type: 'image',
  // 	closeOnContentClick: true,
  // 	closeBtnInside: false,
  // 	fixedContentPos: true,
  // 	mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
  // 	gallery: {
  // 		enabled: true,
  // 		navigateByImgClick: true,
  // 		preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
  // 	},
  // 	image: {
  // 		verticalFit: true
  // 	},
  // 	zoom: {
  // 		enabled: true,
  // 		duration: 300 // don't foget to change the duration also in CSS
  // 	}
  // });

  // $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
  // 	disableOn: 700,
  // 	type: 'iframe',
  // 	mainClass: 'mfp-fade',
  // 	removalDelay: 160,
  // 	preloader: false,

  // 	fixedContentPos: false
  // });

  $("#book_pick_date,#book_off_date").datepicker({
    format: "yyyy-mm-dd",
    startDate : new Date(),
    autoclose: true,
  });
  $("#time_pick").timepicker({
    step: 15,
  });

  $(window).on("scroll resize load", function () {
    var scrollPos = $(window).scrollTop();
    // console.log('scrollposition', scrollPos);
    if (scrollPos > 100) {
      $("#second_header").addClass("fixed_header");
    } else {
      $("#second_header").removeClass("fixed_header");
    }
  });
});
document.addEventListener("DOMContentLoaded", function () {
  var dropdownToggleLinks = document.querySelectorAll(
    ".nav-link.dropdown-toggle i"
  );

  dropdownToggleLinks.forEach(function (link) {
    link.addEventListener("click", function (e) {
      e.preventDefault();
      var dropdownToggle = this.parentNode;
      var dropdownMenu = dropdownToggle.nextElementSibling;

      dropdownToggle.classList.toggle("show");
      dropdownMenu.classList.toggle("show");
    });
  });
});